/** @jsx jsx */
import { DUIText } from "@alch/ui";
import { Link } from "gatsby";
import { memo, ReactElement, useEffect, useState } from "react";
import { jsx } from "theme-ui";

import LoggedInInfo from "../components/LoggedInInfo";
import MasterLayout from "../components/MasterLayout";
import Section from "../components/Section";
import { linkSx } from "../components/styles";
import { getUser, User } from "../http/endpoints";
import { Loading, useWaitForLoad } from "../util/loading";

const SuccessPage = memo(function SuccessPage(): ReactElement {
  const [user, setUser] = useState(Loading.inProgress<User | undefined>());
  const waitForLoad = useWaitForLoad();

  useEffect(() => {
    waitForLoad(getUser(), setUser);
    // eslint-disable-next-line react-hooks/exhaustive-deps -- Legacy (turned on exhaustive-deps for new code)
  }, []);

  useEffect(() => {
    if (user.hasValue && !user.value) {
      window.location.replace("/");
    }
  }, [user]);

  return (
    <MasterLayout title="Success">
      <Section>
        {user.value && <LoggedInInfo user={user.value} />}
        <DUIText mb={6} size="md">
          You may now access Alchemy applications.
        </DUIText>
        <Link to="/change-email">
          <DUIText size="sm" sx={{ ...linkSx }}>
            Change Email
          </DUIText>
        </Link>
        <Link to="/reset-password">
          <DUIText size="sm" sx={{ ...linkSx }}>
            Change Password
          </DUIText>
        </Link>
        <Link to="/logout">
          <DUIText size="sm" sx={{ ...linkSx, color: "danger.core" }}>
            Log out
          </DUIText>
        </Link>
      </Section>
    </MasterLayout>
  );
});
export default SuccessPage;
